import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Carousel from "../components/carousel"
import ImageRow from "../components/image-row"
import Break from "../components/break"
import Titles from "../components/titles"
import SEO from "../components/seo"

const images = [{url:"/angela-goh-1.jpg", details:<div><div className="title">Angeline Goh</div><div className="materials">Being Different</div><div className="dimensions"><span>Wood, epoxy, clay figurines</span><span>125 x 31 x 31 cm</span>
<span>Edition of 10</span></div></div>},{url:"/angela-goh-3.jpg", details:<div><div className="title">Angeline Goh</div><div className="materials">Play Ball </div><div className="dimensions"><span>Clay</span><span>30 x 22 x 13 cm</span>
</div></div>}]
const images_highlight = [
  {url:"/isabel-4.jpg", details:<div><div className="title">Isabelle Devos</div><div className="materials">Flight II</div><div className="dimensions"><span>Bronze</span><span>125cm x 55m x 55cm</span>
</div></div>
},{url:"/isabel-5.jpg", details:<div><div className="title">Isabelle Devos</div><div className="materials">Creation of Man</div><div className="dimensions"><span>Bronze</span><span>125cm x 55m x 55cm</span>
</div></div>
},{url:"/isabel-6.jpg", details:<div><div className="title">Isabelle Devos</div><div className="materials">Metamorphosis</div><div className="dimensions"><span>Bronze</span><span>125cm x 55m</span>
</div></div>
}]
const images_highlight2 = [
  {url:"/isabel-7.jpg", details:<div><div className="title">Isabelle Devos</div><div className="materials">Flight II</div><div className="dimensions"><span>Bronze</span><span>125cm x 55m x 55cm</span>
</div></div>
},{url:"/isabel-1.jpg", details:<div><div className="title">Isabelle Devos</div><div className="materials">Creation of Man</div><div className="dimensions"><span>Bronze</span><span>125cm x 55m x 55cm</span>
</div></div>
},{url:"/isabel-2.jpg", details:<div><div className="title">Isabelle Devos</div><div className="materials">Metamorphosis</div><div className="dimensions"><span>Bronze</span><span>125cm x 55m</span>
</div></div>
}]

const artwork_row = (artworks) => {
      let artwork_rows = artworks.sort((entry,entryb) => {
      if(entry.title.substr(-6) < entryb.title.substr(-6)){
        return -1
      }  else if(entry.title.substr(-6) > entryb.title.substr(-6)) {
        return 1
      }
    return 0}).reduce((resultArray, item, index) => { 
    const chunkIndex = Math.floor(index/3)

    if(!resultArray[chunkIndex]) {
    resultArray[chunkIndex] = [] // start a new chunk
    }

    resultArray[chunkIndex].push({
    type:"image", 
    link:"/" + item.slug,
    url:item.images?.[0]?.url,
    details:<div><div className="title">{item.artist[0].title}</div><div className="materials">{item.title}</div><div className="dimensions">{item.materials}
    </div></div>
    })

    return resultArray
    }, []).map((row) => <ImageRow images={row} /> )
    
    return artwork_rows;
}
const IndexPage = () => (<div>
  <Layout invert={true}>
    <SEO title="Isabelle Devos" />
    <div style={{ }}>
      <h1 style={{marginBottom:"60px", fontSize:"80px",marginTop:"0px", textAlign:"center"}}>Isabelle Devos</h1>
      <Carousel images={["isabel-1.jpg","isabel-2.jpg","isabel-3.jpg"]}/>
      <Titles titles={{"h2":"Works for sale"}} />
      
      <ImageRow images={images_highlight} />
        <ImageRow images={images_highlight2} />

<div className={"titles inline"}><h3>View Artist CV</h3></div>
    </div>

  </Layout>
  <ImageRow rowType={"largeRow"} images={[{type:"text", subtype:"highlight", text: <div style={{padding:"40px"}}><p>Isabelle Devos was born in Belgium, grew up in Canada and moved to rural Australia with her Australian partner and their children.

  </p><p>Since moving to Australia seventeen years ago, exploring, painting, drawing and photographing landscapes and townscapes has been the focus of her creative energy.  

  </p><p>Isabelle Devos exhibits regularly at galleries in Sydney and regional New South Wales and her work is in the collection of a state Art Bank in Canada and the University of New England in Australia as well as international private collections. Devos is the 2020 recipient of the Helen Dangar Memorial Artist Bursary, has recently received a grant from Arts North West to work on landscapes of local national parks and has been awarded a collaborative residency with writer Helena Pastor at Gunyah Artist Residency.

</p><p>Devos has won the Unpacker’s Award at the Norvill Art Prize and the Still Life award in the Armidale Art Prize. Her work has been chosen as a finalist in several major Australian landscape awards including the Country Energy Landscape Prize and the Calleen Art Award.</p></div>, span:"12"}] } />

</div>
)

export default IndexPage
